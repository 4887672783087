import React from "react";

export default (
  <svg
    height="200"
    viewBox="0 0 388 360"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M343.138 5.5498H267.564V137.116H343.138V5.5498Z" fill="#C3BAF5" />
    <path d="M339.054 5.5498H263.481V137.116H339.054V5.5498Z" fill="#F1EFFD" />
    <path d="M331.726 11.7249H270.811V130.942H331.726V11.7249Z" fill="white" />
    <path
      d="M273.898 11.7249H270.811V130.942H273.898V11.7249Z"
      fill="#C3BAF5"
    />
    <path
      d="M336.024 93.8867H266.513V102.176H336.024V93.8867Z"
      fill="#F1EFFD"
    />
    <path d="M214.714 5.5498H139.14V137.116H214.714V5.5498Z" fill="#C3BAF5" />
    <path d="M210.631 5.5498H135.057V137.116H210.631V5.5498Z" fill="#F1EFFD" />
    <path d="M203.301 11.7249H142.387V130.942H203.301V11.7249Z" fill="white" />
    <path
      d="M145.474 11.7249H142.387V130.942H145.474V11.7249Z"
      fill="#C3BAF5"
    />
    <path
      d="M207.598 93.8867H138.088V102.176H207.598V93.8867Z"
      fill="#F1EFFD"
    />
    <path d="M86.29 5.5498H10.716V137.116H86.29V5.5498Z" fill="#C3BAF5" />
    <path d="M82.206 5.5498H6.633V137.116H82.206V5.5498Z" fill="#F1EFFD" />
    <path d="M74.877 11.7249H13.962V130.942H74.877V11.7249Z" fill="white" />
    <path d="M17.049 11.7249H13.962V130.942H17.049V11.7249Z" fill="#C3BAF5" />
    <path d="M79.175 93.8867H9.664V102.176H79.175V93.8867Z" fill="#F1EFFD" />
    <path
      d="M84.021 218.518C70.396 220.397 61.963 190.783 92.061 165.705C90.434 194.734 107.776 215.242 84.021 218.518Z"
      fill="#C3BAF5"
    />
    <path
      d="M74.016 257.31C54.259 243.463 67.782 195.071 128.93 198.578C102.472 234.196 108.46 281.449 74.016 257.31Z"
      fill="#9B8CEE"
    />
    <path
      d="M80.987 256.983C94.12 242.838 75.428 207.25 28.181 220.246C54.852 243.674 58.09 281.643 80.987 256.983Z"
      fill="#9B8CEE"
    />
    <path
      d="M64.145 236.357C59.604 230.828 54.235 224.606 55.54 218.033C56.887 211.398 60.093 206.558 57.507 199.555C54.409 191.16 55.01 181.137 60.247 181.975C65.475 182.817 71.358 188.955 71.201 197.093C71.106 204.97 76.252 207.486 81.096 211.68C85.923 215.837 87.812 221.987 88.678 228.034C90.206 237.573 102.552 235.764 105.835 244.054C109.216 252.042 102.461 262.699 91.652 265.148C80.802 267.605 73.143 269.124 65.774 260.694C58.276 251.729 70.86 244.282 64.145 236.357Z"
      fill="#F1EFFD"
    />
    <path
      d="M101.328 314.398H60.724L57.659 259.081H104.392L101.328 314.398Z"
      fill="#F1EFFD"
    />
    <path d="M107.419 257.948H54.633V263.907H107.419V257.948Z" fill="#C3BAF5" />
    <path d="M368.057 188.417H340.74V306.892H368.057V188.417Z" fill="#C3BAF5" />
    <path
      d="M362.813 306.892H126.658V314.399H362.813V306.892Z"
      fill="#C3BAF5"
    />
    <path d="M340.74 188.417H121.412V306.892H340.74V188.417Z" fill="#F3F8FF" />
    <path d="M330 202.999H132.152V241.854H330V202.999Z" fill="#F1EFFD" />
    <path d="M330 253.453H132.152V292.308H330V253.453Z" fill="#F1EFFD" />
    <path
      d="M169.535 225.091H292.618C294.083 225.091 295.282 223.893 295.282 222.427C295.282 220.962 294.083 219.763 292.618 219.763H169.535C168.07 219.763 166.871 220.962 166.871 222.427C166.871 223.893 168.069 225.091 169.535 225.091Z"
      fill="#F3F8FF"
    />
    <path
      d="M169.535 275.545H292.618C294.083 275.545 295.282 274.346 295.282 272.881C295.282 271.415 294.083 270.216 292.618 270.216H169.535C168.07 270.216 166.871 271.415 166.871 272.881C166.871 274.346 168.069 275.545 169.535 275.545Z"
      fill="#F3F8FF"
    />
    <path
      d="M280.187 171.129C280.187 171.129 311.495 200.788 330.072 171.005C330.072 171.005 325.132 161.438 319.921 160.616C314.71 159.794 314.498 168.898 309.897 166.09C305.296 163.282 299.967 153.673 297.049 158.804C294.131 163.935 290.121 156.621 285.749 156.204C281.376 155.787 280.187 171.129 280.187 171.129Z"
      fill="#F1EFFD"
    />
    <path
      d="M332.063 169.083C332.063 169.083 331.375 188.416 305 188.416C278.625 188.416 277.937 169.083 277.937 169.083H332.063Z"
      fill="#C3BAF5"
    />
    <path
      d="M198.612 159H213.802V188.417H169.195C169.196 172.17 182.366 159 198.612 159Z"
      fill="#F1EFFD"
    />
    <path d="M227.814 159H213.803V188.417H227.814V159Z" fill="#C3BAF5" />
    <path
      d="M194 359.561C301.082 359.561 387.889 354.492 387.889 348.238C387.889 341.985 301.082 336.915 194 336.915C86.9181 336.915 0.110992 341.985 0.110992 348.238C0.110992 354.492 86.9181 359.561 194 359.561Z"
      fill="#F1EFFD"
    />
    <path
      d="M162.025 129.761C161.754 130.172 161.585 130.412 161.371 130.708C161.167 130.995 160.963 131.264 160.758 131.531C160.346 132.071 159.918 132.572 159.488 133.079C158.621 134.075 157.737 135.046 156.803 135.96C154.935 137.791 152.926 139.455 150.83 141.001C148.722 142.531 146.487 143.88 144.18 145.084C143.01 145.655 141.845 146.237 140.636 146.718L138.824 147.439L138.321 147.633C138.039 147.725 137.775 147.825 137.471 147.89C136.883 148.031 136.254 148.12 135.621 148.105C134.348 148.101 133.141 147.738 132.206 147.247C131.257 146.755 130.519 146.167 129.898 145.578C128.667 144.39 127.829 143.175 127.106 141.978C125.696 139.57 124.733 137.185 123.914 134.783C122.315 129.97 121.234 125.21 120.561 120.266L124.741 119.348C125.496 121.538 126.274 123.803 127.141 125.977C127.968 128.171 128.863 130.33 129.857 132.388C130.846 134.437 131.901 136.445 133.1 138.082C133.682 138.9 134.32 139.612 134.871 140.042C135.142 140.255 135.385 140.383 135.517 140.419C135.658 140.461 135.644 140.419 135.577 140.396C135.546 140.395 135.515 140.379 135.509 140.375C135.501 140.382 135.513 140.354 135.517 140.358L135.843 140.2L137.337 139.477C138.332 138.997 139.283 138.426 140.252 137.901C142.151 136.772 143.968 135.517 145.729 134.189C147.467 132.83 149.113 131.355 150.654 129.793C151.442 129.028 152.177 128.218 152.892 127.404C153.241 126.995 153.592 126.579 153.915 126.168L154.398 125.56L154.79 125.038L162.025 129.761Z"
      fill="#CE7A63"
    />
    <path
      d="M149.889 129.333L154.959 141.487L151.293 143.784L145.593 132.747L149.889 129.333Z"
      fill="#14365B"
    />
    <path
      d="M164.239 119.09C155.969 118.976 147.572 129.451 147.359 129.709L153.905 145.086C153.905 145.086 168.453 133.275 169.182 128.659C169.941 123.853 169.482 119.163 164.239 119.09Z"
      fill="#3E21DE"
    />
    <path
      d="M124.031 117.771L118.306 113.322L117.153 122.076C117.153 122.076 122.712 124.258 125.673 122.369L125.593 120.771C125.534 119.592 124.963 118.496 124.031 117.771Z"
      fill="#CE7A63"
    />
    <path
      d="M110.664 112.875L111.02 119.535L117.153 122.076L118.306 113.322L110.664 112.875Z"
      fill="#CE7A63"
    />
    <path
      d="M235.952 117.063L244.791 114.852L238.613 108.543C238.613 108.543 232.878 110.876 234.275 115.879L235.952 117.063Z"
      fill="#CE7A63"
    />
    <path
      d="M249.231 113.278L248.5 106.338L238.613 108.543L244.791 114.852L249.231 113.278Z"
      fill="#CE7A63"
    />
    <path
      d="M207.165 339.467L198.937 340.265L192.074 320.838L200.304 320.04L207.165 339.467Z"
      fill="#CE7A63"
    />
    <path
      d="M295.273 298.641L289.207 304.522L273.636 292.364L279.702 286.482L295.273 298.641Z"
      fill="#CE7A63"
    />
    <path
      d="M287.46 303.384L292.119 295.113C292.289 294.812 292.636 294.686 292.953 294.809L300.028 297.553C300.761 297.837 301.075 298.782 300.673 299.47C298.998 302.326 298.046 303.62 295.997 307.257C294.736 309.495 292.277 314.63 290.536 317.72C288.834 320.742 285.647 319.254 286.096 317.811C288.105 311.343 287.757 307.733 287.22 304.87C287.123 304.353 287.212 303.824 287.46 303.384Z"
      fill="#14365B"
    />
    <path
      d="M197.303 337.742H207.327C207.692 337.742 208.021 337.996 208.119 338.352L210.299 346.283C210.526 347.105 209.919 347.885 209.076 347.87C205.573 347.809 203.862 347.604 199.454 347.604C196.742 347.604 187.909 347.885 184.163 347.885C180.5 347.885 179.734 344.182 181.249 343.847C188.045 342.343 193.786 340.269 195.883 338.29C196.262 337.935 196.77 337.742 197.303 337.742Z"
      fill="#14365B"
    />
    <path
      d="M192.078 320.843L195.613 330.856H204.128L200.311 320.045L192.078 320.843Z"
      fill="#BC6753"
    />
    <path
      d="M279.707 286.484L273.639 292.369L281.668 298.633L287.737 292.748L279.707 286.484Z"
      fill="#BC6753"
    />
    <path
      d="M156.188 121.764C156.188 121.764 152.793 124.35 175.697 169.056C188.656 164.873 202.05 160.55 208.356 158.514C203.517 145.399 200.856 137.208 198.404 107.879C198.404 107.879 191.081 108.599 183.946 110.528C174.288 113.14 169.674 115.078 169.116 115.316C162.934 117.954 156.188 121.764 156.188 121.764Z"
      fill="#3E21DE"
    />
    <path
      d="M177.086 94.3049C177.69 99.3789 179.579 108.423 183.945 110.529C183.945 110.529 184.203 115.79 175.337 118.653C165.588 121.799 169.116 115.317 169.116 115.317C174.027 112.329 172.615 108.425 170.491 105.017L177.086 94.3049Z"
      fill="#CE7A63"
    />
    <path
      d="M160.701 119.337C163.112 118.087 166.181 116.569 169.117 115.313C173.808 113.309 178.372 112.035 183.949 110.525C186.803 109.749 189.683 109.165 192.113 108.761C192.34 109.439 191.647 111.198 189.958 113.625C184.611 118.073 173.203 123.839 164.79 123.627C161.157 122.263 160.242 120.814 160.701 119.337Z"
      fill="#CE7A63"
    />
    <path
      d="M189.957 113.626C187.642 116.953 183.444 121.541 177.077 126.384C171.276 125.519 167.358 124.594 164.788 123.628C173.202 123.84 184.61 118.075 189.957 113.626Z"
      fill="#14365B"
    />
    <path
      d="M174.398 98.6741L170.494 105.01C170.999 105.816 171.46 106.65 171.801 107.495C173.739 106.545 176.215 103.735 175.743 101.537C175.508 100.442 174.731 99.1131 174.398 98.6741Z"
      fill="#BC6753"
    />
    <path
      d="M179.069 84.9171C179.573 93.2881 179.965 96.8201 176.29 101.597C170.761 108.783 160.369 107.572 157.374 99.5201C154.678 92.2721 154.306 79.8011 162.035 75.5011C169.65 71.2651 178.565 76.5451 179.069 84.9171Z"
      fill="#CE7A63"
    />
    <path
      d="M180.528 81.931C189.057 81.931 195.972 75.0165 195.972 66.487C195.972 57.9575 189.057 51.043 180.528 51.043C171.999 51.043 165.084 57.9575 165.084 66.487C165.084 75.0165 171.999 81.931 180.528 81.931Z"
      fill="#0F264C"
    />
    <path
      d="M180.222 80.3892C181.454 78.518 179.872 75.3008 176.688 73.2033C173.504 71.1058 169.923 70.9222 168.691 72.7934C167.458 74.6645 169.04 77.8818 172.225 79.9793C175.409 82.0768 178.989 82.2603 180.222 80.3892Z"
      fill="#F1EFFD"
    />
    <path
      d="M175.021 90.627C175.854 88.71 172.956 83.008 173.583 80.875C171.916 79.333 155.093 84.814 156.449 80.806C157.746 76.973 161.848 71.875 171.208 74.007C181.245 76.293 182.083 84.94 180.958 87.699C179.833 90.458 178.25 93.332 178.25 93.332L175.021 90.627Z"
      fill="#0F264C"
    />
    <path
      d="M181.275 92.2559C181.315 93.9939 180.549 95.5889 179.546 96.6059C178.037 98.1359 176.219 97.0439 175.748 95.0619C175.324 93.2789 175.598 90.3339 177.541 89.5469C179.455 88.7709 181.229 90.2689 181.275 92.2559Z"
      fill="#CE7A63"
    />
    <path
      d="M175.696 169.056C175.696 169.056 160.557 242.897 169 267.25C177.781 292.58 192.074 327.943 192.074 327.943L205.42 327.177C205.42 327.177 194.393 282.494 191.709 262.318C188.01 234.501 203.887 159.957 203.887 159.957L175.696 169.056Z"
      fill="#C3BAF5"
    />
    <path
      d="M206.475 327.5L191.02 328.386L188.542 322.312L205.583 320.542L206.475 327.5Z"
      fill="#7864E8"
    />
    <path
      d="M191.71 174.744C189.39 183.324 191.33 196.184 195.71 205.203C196.78 198.193 197.92 191.423 199 185.353C196.75 181.043 194.27 177.144 191.71 174.744Z"
      fill="#7864E8"
    />
    <path
      d="M184.321 166.272C184.321 166.272 206.802 219.958 221.685 249.5C233.706 273.36 278.222 298.33 278.222 298.33L286.195 289.851C286.195 289.851 252.918 260.299 244.62 244.119C218.06 192.33 223.521 169.713 208.355 158.515L184.321 166.272Z"
      fill="#C3BAF5"
    />
    <path
      d="M287.706 288.641L276.712 300.331L269.75 296.667L282.417 283.75L287.706 288.641Z"
      fill="#7864E8"
    />
    <path
      d="M208.226 156.44L210.642 158.888C210.83 159.078 210.636 159.396 210.258 159.517L176.008 170.573C175.714 170.668 175.423 170.602 175.345 170.424L174.107 167.595C174.021 167.399 174.221 167.145 174.544 167.041L207.615 156.366C207.857 156.288 208.104 156.318 208.226 156.44Z"
      fill="#14365B"
    />
    <path
      d="M204.978 161.572L205.864 161.286C206.041 161.229 206.145 161.095 206.097 160.988L204.455 157.324C204.407 157.217 204.224 157.176 204.047 157.233L203.161 157.519C202.985 157.576 202.88 157.71 202.928 157.817L204.57 161.481C204.617 161.587 204.801 161.629 204.978 161.572Z"
      fill="#9FC6FF"
    />
    <path
      d="M178.6 170.086L179.485 169.8C179.662 169.743 179.767 169.609 179.718 169.502L178.077 165.838C178.029 165.731 177.846 165.69 177.669 165.747L176.783 166.033C176.607 166.09 176.502 166.224 176.55 166.332L178.192 169.996C178.239 170.102 178.423 170.143 178.6 170.086Z"
      fill="#9FC6FF"
    />
    <path
      d="M191.789 165.829L192.675 165.543C192.851 165.486 192.956 165.352 192.908 165.245L191.266 161.581C191.218 161.474 191.035 161.433 190.858 161.49L189.972 161.776C189.795 161.833 189.69 161.967 189.739 162.075L191.38 165.739C191.429 165.845 191.612 165.886 191.789 165.829Z"
      fill="#9FC6FF"
    />
    <path
      d="M166.091 90.0914C166.533 90.0375 166.824 89.4454 166.741 88.7689C166.659 88.0924 166.234 87.5877 165.792 87.6417C165.35 87.6956 165.059 88.2876 165.141 88.9641C165.224 89.6406 165.649 90.1453 166.091 90.0914Z"
      fill="#0F264C"
    />
    <path
      d="M158.45 91.0168C158.892 90.9628 159.184 90.3707 159.101 89.6942C159.019 89.0177 158.593 88.5131 158.151 88.5671C157.708 88.621 157.417 89.2132 157.499 89.8897C157.582 90.5661 158.007 91.0708 158.45 91.0168Z"
      fill="#0F264C"
    />
    <path
      d="M158.353 88.5979L156.671 88.3279C156.671 88.3279 157.66 89.4949 158.353 88.5979Z"
      fill="#0F264C"
    />
    <path
      d="M161.266 90.627C161.266 90.627 160.474 93.68 159.382 95.239C160.377 95.964 161.849 95.397 161.849 95.397L161.266 90.627Z"
      fill="#BA4D3C"
    />
    <path
      d="M164.53 97.7721C164.43 97.7721 164.344 97.6971 164.332 97.5961C164.319 97.4861 164.397 97.3871 164.506 97.3731C167.467 97.0121 168.609 94.7671 168.62 94.7451C168.669 94.6451 168.789 94.6031 168.888 94.6541C168.987 94.7031 169.028 94.8221 168.979 94.9221C168.93 95.0221 167.737 97.3821 164.554 97.7711C164.547 97.7721 164.539 97.7721 164.53 97.7721Z"
      fill="#0F264C"
    />
    <path
      d="M167.698 86.376C167.605 86.376 167.512 86.344 167.436 86.279C166.098 85.124 164.705 85.492 164.647 85.51C164.435 85.569 164.214 85.446 164.153 85.234C164.093 85.022 164.215 84.802 164.426 84.741C164.503 84.72 166.282 84.228 167.959 85.673C168.126 85.818 168.145 86.07 168.001 86.238C167.921 86.329 167.81 86.376 167.698 86.376Z"
      fill="#0F264C"
    />
    <path
      d="M155.152 86.6151C155.092 86.6151 155.03 86.6011 154.973 86.5721C154.775 86.4731 154.696 86.2321 154.795 86.0351C155.79 84.0561 157.624 83.8291 157.701 83.8211C157.921 83.8011 158.118 83.9551 158.143 84.1751C158.167 84.3941 158.01 84.5921 157.792 84.6171C157.731 84.6241 156.303 84.8171 155.509 86.3951C155.44 86.5341 155.299 86.6151 155.152 86.6151Z"
      fill="#0F264C"
    />
    <path
      d="M204.975 115.689L205.351 116.168L205.821 116.736C206.139 117.119 206.475 117.511 206.819 117.893C207.513 118.658 208.233 119.416 208.998 120.138C210.499 121.606 212.092 123.004 213.769 124.302C215.469 125.568 217.213 126.787 219.036 127.892C219.951 128.435 220.883 128.956 221.818 129.472L223.247 130.199L223.945 130.553C224.113 130.628 224.284 130.714 224.443 130.77C225.1 131.034 225.618 131.105 226.053 131.099C226.487 131.083 226.92 130.95 227.452 130.581C228.529 129.86 229.664 128.253 230.541 126.456C231.449 124.648 232.173 122.609 232.767 120.515C233.398 118.438 233.889 116.217 234.301 114.115L238.572 114.385C238.617 119.274 238.116 123.987 236.492 128.903C236.078 130.132 235.537 131.353 234.839 132.579C234.136 133.795 233.252 135.039 231.995 136.135C230.76 137.229 229.06 138.158 227.212 138.481C225.371 138.82 223.543 138.597 222.027 138.121C221.635 138.009 221.276 137.871 220.911 137.735L220.04 137.374L218.337 136.659C217.214 136.154 216.101 135.618 215.009 135.043C212.826 133.888 210.716 132.589 208.7 131.148C206.698 129.688 204.76 128.129 202.95 126.407C202.045 125.547 201.18 124.637 200.334 123.697C199.911 123.222 199.494 122.745 199.088 122.237L198.484 121.457C198.272 121.176 198.106 120.951 197.832 120.552L204.975 115.689Z"
      fill="#CE7A63"
    />
    <path
      d="M212.086 120.96L205.834 131.471L202.776 128.585L208.556 118.065L212.086 120.96Z"
      fill="#14365B"
    />
    <path
      d="M198.403 107.879C201.996 107.818 210.736 118.141 210.736 118.141L203.142 131.371C203.142 131.371 193.463 122.838 193.473 118.165C193.483 113.299 194.303 107.949 198.403 107.879Z"
      fill="#3E21DE"
    />
    <path
      d="M285.821 307.869C285.567 307.869 285.313 307.773 285.062 307.582C284.791 307.375 284.752 307.129 284.768 306.959C284.864 305.884 287.566 304.46 287.873 304.302C287.944 304.267 288.03 304.275 288.092 304.325C288.154 304.375 288.18 304.458 288.157 304.535C287.845 305.615 287.171 307.478 286.151 307.814C286.042 307.852 285.932 307.869 285.821 307.869ZM287.631 304.889C286.567 305.49 285.218 306.428 285.167 306.994C285.161 307.065 285.171 307.16 285.307 307.264C285.557 307.456 285.791 307.512 286.028 307.435C286.577 307.253 287.164 306.314 287.631 304.889Z"
      fill="#407BFF"
    />
    <path
      d="M285.446 305.254C284.921 305.254 284.457 305.164 284.196 304.94C284.055 304.819 283.847 304.545 284.041 304.08C284.163 303.784 284.381 303.58 284.688 303.473C285.848 303.077 287.974 304.255 288.063 304.306C288.125 304.341 288.165 304.407 288.166 304.478C288.167 304.549 288.13 304.616 288.069 304.653C287.557 304.959 286.4 305.254 285.446 305.254ZM285.244 303.788C285.088 303.788 284.944 303.808 284.819 303.85C284.622 303.918 284.488 304.043 284.41 304.231C284.312 304.469 284.384 304.573 284.457 304.635C284.905 305.022 286.568 304.858 287.522 304.473C286.925 304.181 285.955 303.788 285.244 303.788Z"
      fill="#407BFF"
    />
    <path
      d="M192.858 339.397C192.083 339.397 191.361 339.278 190.9 338.929C190.585 338.691 190.414 338.367 190.391 337.966C190.374 337.667 190.518 337.505 190.642 337.421C191.553 336.804 194.965 338.357 195.64 338.673C195.717 338.709 195.763 338.79 195.754 338.876C195.744 338.961 195.682 339.03 195.598 339.049C194.872 339.211 193.824 339.397 192.858 339.397ZM191.261 337.668C191.089 337.668 190.954 337.694 190.867 337.753C190.825 337.781 190.784 337.826 190.791 337.943C190.807 338.225 190.922 338.444 191.142 338.61C191.93 339.205 193.812 338.979 194.907 338.781C193.674 338.245 192.064 337.668 191.261 337.668Z"
      fill="#407BFF"
    />
    <path
      d="M195.556 339.055C195.531 339.055 195.504 339.05 195.48 339.04C194.349 338.577 192.067 336.747 192.196 335.802C192.224 335.595 192.365 335.335 192.881 335.274C194.869 335.014 195.716 338.657 195.752 338.81C195.769 338.883 195.743 338.959 195.687 339.007C195.648 339.038 195.603 339.055 195.556 339.055ZM193.063 335.662C193.019 335.662 192.974 335.665 192.928 335.67C192.718 335.695 192.605 335.758 192.592 335.856C192.518 336.405 194.094 337.85 195.246 338.483C194.983 337.608 194.263 335.662 193.063 335.662Z"
      fill="#407BFF"
    />
    <path
      d="M166 87.664L164.318 87.394C164.318 87.394 165.307 88.562 166 87.664Z"
      fill="#0F264C"
    />
    <path
      d="M103.444 17.7781C101.666 20.0001 91.916 22.6111 84.166 21.8881C81.499 39.4051 90.833 59.5831 90.833 59.5831C90.833 59.5831 111.083 57.1661 111.583 48.8331C112.083 40.5001 103.444 17.7781 103.444 17.7781Z"
      fill="#1D0F68"
    />
    <path
      d="M256.729 4.4541C258.541 6.9991 267.743 9.5331 274.84 8.2091C278.652 28.9521 271.594 53.5851 271.594 53.5851C271.594 53.5851 252.716 51.9101 251.612 41.9961C250.507 32.0821 256.729 4.4541 256.729 4.4541Z"
      fill="#1D0F68"
    />
    <path
      d="M124.011 184.069C123.404 184.155 122.841 183.732 122.756 183.125L98.951 14.6601C98.865 14.0531 99.288 13.4911 99.895 13.4051C100.503 13.3191 101.065 13.7421 101.151 14.3501L124.955 182.815C125.042 183.422 124.618 183.984 124.011 184.069Z"
      fill="#3E21DE"
    />
    <path
      d="M237.174 171.547C237.781 171.633 238.344 171.21 238.429 170.603L262.234 2.13798C262.32 1.53098 261.897 0.967979 261.29 0.881979C260.682 0.796979 260.12 1.21898 260.034 1.82698L236.229 170.292C236.143 170.899 236.565 171.461 237.174 171.547Z"
      fill="#3E21DE"
    />
    <path
      d="M98.055 18.2231L102.547 51.2971C102.668 52.1871 103.549 52.7611 104.413 52.5141C114.472 49.6351 170.381 35.2711 256.337 44.4131C257.098 44.4941 257.796 43.9881 257.941 43.2371C259.561 34.8571 262.353 10.9901 263.057 4.88308C263.15 4.08108 262.579 3.35608 261.779 3.25508C250.236 1.79308 171.466 -6.93992 99.062 16.6171C98.383 16.8361 97.959 17.5151 98.055 18.2231Z"
      fill="#28158D"
    />
    <path
      d="M112.477 23.955L117.219 22.993L120.575 31.705L121.424 22.141L126.15 21.183L130.301 29.721L130.364 20.329L135.08 19.373L134.463 36.275L129.567 37.267L124.879 27.655L123.91 38.415L119.014 39.407L112.477 23.955Z"
      fill="white"
    />
    <path
      d="M137.403 19.117L150.895 17.137L151.335 20.624L142.888 21.864L143.216 24.46L151.051 23.31L151.471 26.641L143.636 27.791L144.043 31.011L152.733 29.736L153.2 33.435L139.464 35.451L137.403 19.117Z"
      fill="white"
    />
    <path
      d="M154.325 16.7262L159.38 16.1812L160.463 28.5532L168.353 27.7022L168.707 31.7442L155.762 33.1402L154.325 16.7262Z"
      fill="white"
    />
    <path
      d="M181.062 24.062L185.573 25.092C185.339 26.363 184.923 27.442 184.325 28.327C183.726 29.211 182.963 29.901 182.033 30.396C181.104 30.891 179.906 31.191 178.436 31.295C176.654 31.422 175.184 31.267 174.027 30.827C172.87 30.389 171.848 29.543 170.961 28.292C170.072 27.041 169.576 25.395 169.471 23.351C169.331 20.628 169.945 18.483 171.312 16.916C172.68 15.351 174.684 14.472 177.325 14.285C179.39 14.137 181.035 14.44 182.262 15.194C183.486 15.948 184.431 17.173 185.095 18.87L180.672 20.189C180.489 19.699 180.306 19.344 180.123 19.124C179.819 18.755 179.456 18.48 179.036 18.3C178.615 18.12 178.151 18.048 177.644 18.084C176.496 18.166 175.639 18.693 175.076 19.665C174.649 20.386 174.473 21.483 174.549 22.957C174.642 24.782 174.983 26.013 175.57 26.65C176.157 27.288 176.949 27.57 177.949 27.498C178.918 27.43 179.637 27.103 180.105 26.521C180.573 25.938 180.892 25.119 181.062 24.062Z"
      fill="white"
    />
    <path
      d="M187.421 22.1551C187.386 19.4601 188.105 17.3391 189.58 15.7901C191.054 14.2411 193.124 13.4221 195.79 13.3351C198.523 13.2471 200.639 13.9151 202.136 15.3401C203.634 16.7661 204.401 18.8071 204.435 21.4641C204.459 23.3931 204.157 24.9851 203.526 26.2391C202.897 27.4951 201.975 28.4861 200.764 29.2121C199.551 29.9381 198.034 30.3311 196.212 30.3901C194.36 30.4511 192.823 30.2041 191.602 29.6511C190.38 29.0991 189.384 28.1931 188.614 26.9341C187.843 25.6761 187.446 24.0831 187.421 22.1551ZM192.495 22.0111C192.516 23.6771 192.84 24.8631 193.466 25.5711C194.092 26.2791 194.934 26.6151 195.995 26.5801C197.085 26.5451 197.924 26.1611 198.513 25.4291C199.101 24.6961 199.383 23.4081 199.36 21.5621C199.339 20.0091 199.012 18.8841 198.381 18.1871C197.748 17.4911 196.898 17.1601 195.83 17.1951C194.806 17.2281 193.99 17.6191 193.379 18.3671C192.768 19.1161 192.473 20.3311 192.495 22.0111Z"
      fill="white"
    />
    <path
      d="M207.511 13.2891L214.183 13.3421L216.482 23.4071L219.31 13.3841L225.978 13.4371L225.527 29.9441L221.373 29.9101L221.716 17.3221L218.171 29.8851L214.411 29.8551L211.559 17.2411L211.215 29.8301L207.061 29.7971L207.511 13.2891Z"
      fill="white"
    />
    <path
      d="M229.639 13.583L243.211 14.187L242.988 17.708L234.491 17.33L234.325 19.951L242.207 20.302L241.993 23.665L234.112 23.315L233.906 26.565L242.648 26.955L242.412 30.69L228.594 30.075L229.639 13.583Z"
      fill="white"
    />
  </svg>
);
