import React, { useEffect } from "react";
import SuccessSection from "../containers/SuccessSection";
import MainLayout from "../components/MainLayout";

function checkQueryParameters() {
  if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search);
    const netlify = urlParams.get("netlify");
    const isNetlify = netlify === "true";
    const hasLinks = urlParams.get("hasLinks") === "true";
    const name = urlParams.get("name") || "";

    const params = { hasLinks, isNetlify, name };
    return params;
  } else {
    return { isNetlify: false, name: "", hasLinks: false };
  }
}

export default ({ location }) => {
  const { isNetlify, name, hasLinks } = checkQueryParameters();

  let firstname, title, message;
  if (isNetlify) {
    firstname = name;
    title = "Hey";
    message =
      "Your message has been recieved! Within the next few minutes, you'll have a friendly reply";
  } else {
    firstname =
      location && location.state ? location.state.firstname : "Stranger";
    title = location && location.state ? location.state.title : "Hey";
    message =
      location && location.state
        ? location.state.message
        : "You propably lost your path! Don't worry we are here to help you";
  }

  return (
    <MainLayout
      hasGradient
      title="Success"
      pathname="/success"
      description="Your registration is completed!"
    >
      <SuccessSection
        firstname={firstname}
        title={title}
        message={message}
        hasLinks={hasLinks}
      />
    </MainLayout>
  );
};
